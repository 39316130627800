/* eslint-disable camelcase */
import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const NotificationContext = createContext({});

const { Provider } = NotificationContext;

/** ============================================================================
 * CSS fixed notification provider.
 */
const NotificationProvider = ({ children }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [active, setActive] = useState(true);

  // ---------------------------------------------------------------------------
  // methods

  const hide = () => {
    setActive(false);
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  //

  // ---------------------------------------------------------------------------
  // render

  return (
    <Provider
      value={{
        active,
        hide
      }}
    >
      {children}
    </Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default NotificationProvider;
