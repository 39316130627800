/* eslint-disable camelcase */
import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useKeyPress } from "~hooks";

export const SearchContext = createContext({});

/** ============================================================================
 * CSS fixed bio provider.
 */
const SearchProvider = ({ children }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [searchTerm, setSearchTerm] = useState(null);

  // ---------------------------------------------------------------------------
  // methods

  // ...

  // ---------------------------------------------------------------------------
  // lifecycle

  // useEffect(() => {
  //   console.log(`searchTerm: `, searchTerm);
  // }, [searchTerm]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <SearchContext.Provider
      value={{
        searchTerm,
        setSearchTerm
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

SearchProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default SearchProvider;
