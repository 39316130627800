import { useState, useEffect } from "react";
import { useWindowDimensions } from "~hooks";

const useDevice = () => {
  const [device, setDevice] = useState(null);

  const { screen } = useWindowDimensions();

  //

  const isDesktop = () => device === `desktop`;
  const isTablet = () => device === `tablet`;
  const isMobile = () => device === `mobile`;

  //

  useEffect(() => {
    switch (screen) {
      case `xs`:
        setDevice(`mobile`);
        break;
      case `sm`:
        setDevice(`tablet`);
        break;
      default:
        setDevice(`desktop`);
        break;
    }
  }, [screen]);

  //

  return {
    device,
    isDesktop,
    isTablet,
    isMobile
  };
};

export default useDevice;
