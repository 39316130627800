import { useContext } from "react";
import { SearchContext } from "~context/SearchContext.jsx";

const useSearch = () => {
  const search = useContext(SearchContext);

  return search;
};

export default useSearch;
