/* eslint-disable camelcase */
import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useKeyPress } from "~hooks";

export const BioContext = createContext({});

/** ============================================================================
 * CSS fixed bio provider.
 */
const BioProvider = ({ children }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const escPressed = useKeyPress(`Escape`);

  const [activeBio, setActiveBio] = useState(null);
  const [visible, setVisible] = useState(false);

  // ---------------------------------------------------------------------------
  // methods

  // ...

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (activeBio) {
      setVisible(true);
    }
  }, [activeBio]);

  useEffect(() => {
    setVisible(false);
  }, [escPressed]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <BioContext.Provider
      value={{
        activeBio,
        setActiveBio,
        visible,
        setVisible
      }}
    >
      {children}
    </BioContext.Provider>
  );
};

BioProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default BioProvider;
