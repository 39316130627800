import { useContext } from "react";
import { BioContext } from "~context/BioContext.jsx";

const useBio = () => {
  const bio = useContext(BioContext);

  return bio;
};

export default useBio;
