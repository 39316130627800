import { graphql, useStaticQuery } from "gatsby";

const useSanityGlobals = () => {
  const data = useStaticQuery(graphql`
    query useSanityGlobals {
      sanityGlobals {
        socialHeading
      }
    }
  `);

  return data;
};

export default useSanityGlobals;
