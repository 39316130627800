import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useCookies } from "react-cookie";

export const LocationContext = createContext({});

const LocationProvider = ({ children }) => {
  // ===========================================================================
  // context / ref / state

  const [cookies, setCookie] = useCookies(null);

  const [location, setLocation] = useState(null);

  // ===========================================================================
  // lifecycle

  useEffect(() => {
    if (typeof window === `undefined`) {
      return;
    }

    if (
      cookies?.[`${process.env.GATSBY_REGION_CODE}_location`]?.continent_code
    ) {
      setLocation(cookies[`${process.env.GATSBY_REGION_CODE}_location`]);
    } else {
      axios
        .get(`https://ipapi.co/json/`)
        .then((response) => {
          const { data } = response;

          if (data?.continent_code) {
            setLocation(data);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    }
  }, []);

  useEffect(() => {
    if (location?.continent_code) {
      setCookie([`${process.env.GATSBY_REGION_CODE}_location`], location, {
        path: `/`
      });

      // -- location <> locale map goes here --
      // supported locales would be mapped based on a country code switch() {}
    }
  }, [location]);

  // ===========================================================================
  // render

  return (
    <LocationContext.Provider
      value={{
        location
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

LocationProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default LocationProvider;
