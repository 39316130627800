/* eslint-disable import/prefer-default-export */

import "lazysizes";

import React from "react";
import AppProvider from "~context/AppContext.jsx";
import LocationProvider from "~context/LocationContext.jsx";
import NotificationProvider from "~context/NotificationContext.jsx";
import BioProvider from "~context/BioContext.jsx";
import SearchProvider from "~context/SearchContext.jsx";

import "./src/assets/css/tailwind.build.css";

export const wrapRootElement = ({ element }) => (
  <>
    <AppProvider>
      <SearchProvider>
        <NotificationProvider>
          <LocationProvider>
            <BioProvider>{element}</BioProvider>
          </LocationProvider>
        </NotificationProvider>
      </SearchProvider>
    </AppProvider>
  </>
);
